import { createRouter, createWebHistory } from "vue-router";
import MainRoutes from "./MainRoutes";
import AuthRoutes from "./AuthRoutes.js";
import useProfileStore from "@/store/profile.js";

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/:pathMatch(.*)*",
      component: () => import("@/views/pages/ErrorPage404.vue"),
    },
    MainRoutes,
    AuthRoutes,
  ],
});

router.beforeEach((to, from, next) => {
  const profileStore = useProfileStore();
  var auth = profileStore.auth;
  const needAuth = to.meta.requiresAuth;

  if (needAuth && !auth) {
    router.push({
      path: "/auth/login",
    })
  } else {
    
    next()
  }
})