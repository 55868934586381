const MainRoutes = {
    path: '/main',
    meta: {
        requiresAuth: true
    },
    redirect: '/main',
    /*component: () => import('@/layouts/full/FullLayout.vue'),*/
    component: () => import('@/layouts/default/DefaultVue.vue'),
    children: [
        {
            name: 'Inicio',
            path: '/',
            //component: () => import('@/views/dashboard/index.vue')
            component: () => import('@/views/inicio/indexInicio.vue')
        },
        {
            name: 'Camion',
            path: '/camion',            
            component: () => import('@/views/inicio/CrearCamion.vue')
        },
        {
            name: 'Logout',
            path: '/auth/logout',
            component: () => import('@/views/auth/LogoutAuth.vue')
        },
    ]
};

export default MainRoutes;
